






















import Vue from 'vue';
import EnumerationUtils, { Enumeration } from '@/utils/EnumerationUtils';
import { DataTableHeader } from 'vuetify';

export default Vue.extend({
  computed: {
    headers(): DataTableHeader<Enumeration>[] {
      return [
        {
          text: this.$tc('enumeration.family_key'),
          value: 'family_key',
        },
        {
          text: this.$tc('enumeration.enum_key'),
          value: 'enum_key',
        },
        {
          text: this.$tc('enumeration.name'),
          value: 'name',
        },
        {
          text: this.$tc('enumeration.sort'),
          value: 'sort',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },

  data: (): {
    items?: Enumeration[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = undefined;
      this.items = await EnumerationUtils.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: Enumeration) {
      await this.$router.push({
        name: 'enumeration-edit',
        params: { id: item.enum_id?.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'enumeration-create',
      });
    },
  },
});
